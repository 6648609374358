import * as React from 'react';

import config from '../../config';
import Button from '../styleguide/Button';
import AccountTipImage from '../../assets/YourAccountTip.png';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import LightbulbIcon from '@material-ui/icons/EmojiObjects';
import ReferralLinkForm from '../refer/ReferralLinkForm';
import styles from './RegistrationSuccess.module.css';

import PropTypes from 'prop-types';

const RegistrationSuccessMsg = ({
  isCheckout,
  onContinue
}) => {

  const handleContinue = React.useCallback(() => {
    if (onContinue) {
      // Continue to Shopping/Checkout
      onContinue();
    }
  }, [onContinue])

  return (
    <div className={styles.successWrap}>
      <div className={styles.successInner}>
        <div className={styles.successMsg}>
          <span className={styles.exclaim}>
            <CheckCircleRoundedIcon style={{fontSize:'inherit', verticalAlign:'-4px'}} />
            {'  '}<span>Success!</span>
          </span>
          {'  '}You're registered.
          {'  '}<span role="img" aria-label="celebrate">🎉</span>
        </div>
        <Button
          isCentered
          text={ isCheckout
            ? 'Continue to Checkout'
            : 'Continue Shopping'
          }
          handleClick={handleContinue} />
      </div>

      { config.ENABLE_CREDITS &&
        <div className={styles.referWrap}>
          <ReferralLinkForm />
        </div>
      }

      <div className={styles.accountTip}>
        <LightbulbIcon style={{color:'inherit', fontSize: 24, verticalAlign:-6}} /> Manage your account using the <i>Your Account</i> menu option
        <img height="48" alt="Your Account Menu Option" src={AccountTipImage} />
      </div>
    </div>
  );
};

RegistrationSuccessMsg.propTypes = {
  isCheckout: PropTypes.bool, // for messaging
  onContinue: PropTypes.func.isRequired
};

export default RegistrationSuccessMsg;
