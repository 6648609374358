import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import ProductCarousel from './ProductCarousel';
import { getSortPrice } from '../products/DisplayPrice';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';

const ProductsByPriceCarousel = ({
  categoryName
}) => {

  const { productsByCategory } = React.useContext(ProductPreviewContext);
  const [sortedProducts, setSortedProducts] = React.useState();
  const [categoryProducts, setCategoryProducts] = React.useState();

  // Sort products by price
  React.useEffect(() => {
    const products = productsByCategory.get(categoryName);
    if (products?.length) {
      setSortedProducts([ ...products].sort((a,b) => getSortPrice(a) - getSortPrice(b)));
      setCategoryProducts(products);
    }
  }, [productsByCategory, categoryName])

  return ( sortedProducts?.length
    ? <ProductCarousel
        carouselTitle={`${categoryName} by Price`}
        categoryName={categoryName}
        categoryCount={categoryProducts.length}
        carouselProducts={sortedProducts}
        withTypeFilter
        withMoreCard
        trackingPrefix='by_price'
        seeAllTitle={categoryName}
        seeAllClick={() => {
          navigateTop(`/products/${categoryName}`);
        }} />
    : <Spinner />
  )
}

ProductsByPriceCarousel.propTypes = {
  categoryName: PropTypes.string.isRequired
}

export default ProductsByPriceCarousel;
