import * as React from 'react';

import { trackEvent, trackingString } from '../analytics/tracking';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import { ALL_TYPES, HIGH_THC, LOW_PRICE } from './useCategoryProductsByType';
import { Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './ProductCarousel.module.css';

const CarouselHeaderWithTypeFilter = ({carouselTitle, category, type, setType}) => {

  // Popular Concentrates, prevent wrapping
  const longTitle = carouselTitle.length > 18;

  const { typesByCategory } = React.useContext(ProductPreviewContext);
  const filterOptions = Array.from(typesByCategory.get(category) ||  new Set()).sort();
  filterOptions.unshift(ALL_TYPES);
  filterOptions.push(HIGH_THC);
  filterOptions.push(LOW_PRICE);

  return (
    <div className={styles.headerGrid}>
      <span className={`${styles.categoryName} ${longTitle ? styles.longCategoryName : ''}`}>{carouselTitle}</span>
      { filterOptions.length > 2 &&
        <span className={styles.headerSelectWrap}>
          <Select
            className={styles.select}
            label="Type"
            value={type}
            onChange={(event) => {
              const { value } = event.target;
              setType(value);
              trackEvent(`csl_filter_${trackingString(category)}_${trackingString(value)}`);
            }}>
              { filterOptions.map(type => (
                <MenuItem
                  key={`${category}_${type}`}
                  className={styles.option}
                  value={type}>
                    {type}
                </MenuItem>
              ))}
          </Select>
        </span>
      }
    </div>
  )
}

CarouselHeaderWithTypeFilter.propTypes = {
  carouselTitle: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
}

export default CarouselHeaderWithTypeFilter;
