import * as React from 'react';

import { navigateTop, getScrollPixels } from '../routing/router-utils';
import { getUrlHashedValue } from '../util/storage-utils';
import { useAnalyticsStore } from '../../App';
import { UserContext } from '../providers/UserProvider';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import { STACKING } from '../layout/stackingConstants';
import PreviewProducts from './PreviewProducts';
import SubCategoryCarousels from './SubCategoryCarousels';
import useNewlyAddedProducts from '../newProducts/useNewlyAddedProducts';
import NewThisSessionCarousel from '../newProducts/NewThisSessionCarousel';
import NewlyAddedLinks from '../newProducts/NewlyAddedLinks';
import BrandCarousels from '../brand/BrandCarousels';
import BundleDealLinksForCategory from '../deals/BundleDealLinksForCategory';
import PromoBannerSection from '../banners/PromoBannerSection';
import { dealsCategory } from '../common/CategoryImage';
import SaleItemsProvider from '../deals/SaleItemsProvider';
import CurrentTimeSlotProvider from '../timeSlots/CurrentTimeSlotProvider';
import DealsPage from '../deals/DealsPage';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import Header, { HeaderSize } from '../styleguide/Header';
import SearchTypeAhead from '../search/SearchTypeAhead';
import ServiceMessages from '../dispensary/ServiceMessages';
import ReOrderSection from '../reorder/ReOrderSection';
import ProductFilterCarousel from '../dispensary/ProductFilterCarousel';
import PromoText from '../dispensary/PromoText';
import ReferredSessionBanner from '../refer/ReferredSessionBanner';
import VerifyCartReminderProducts from './VerifyCartReminderProducts';
import ProductRequestAccordion from '../survey/ProductRequestAccordion';
import KlaviyoSubscribeModal from '../klaviyo/KlaviyoSubscribeModal';
import Spinner from '../common/Spinner';
import PageTitle from '../common/PageTitle';

import styles from './Home.module.css';

const Home = ({category, /* via router */}) => {

  const { trackEvent } = useAnalyticsStore();

  // This is the marketing site redirect with address
  const { user, searchLocation, setSearchLocation } = React.useContext(UserContext);
  React.useEffect(() => {
    if (window.location.hash && user?.isAnonymous && !searchLocation) {
      const address = getUrlHashedValue('address');
      if (address) {
        setSearchLocation(JSON.parse(window.atob(address)));
      }
    }
  }, [user, searchLocation, setSearchLocation]);

  const { activeCategories:productCategories } = React.useContext(ProductPreviewContext);
  // Current category from path
  const [ currentCategory, setCurrentCategory] = React.useState();

  // Handle category from path
  React.useEffect(() => {
    if (category && category !== currentCategory) {
      setCurrentCategory(category);
    }
  }, [category, currentCategory])

  // Category click (not used for home/popular)
  const filterClick = React.useCallback((categoryName) => {
    /**
     * Collapse filter on category change
     * We need to scroll @ 118 pixels to reach the scroll target and collapse category nav
     * We're using target.offsetTop currently which should really be getBoundingRect
     * but that results in odd behavior.
     * For now, we use offsetTop (~37) + MAGIC_SCROLL_PIXELS (92) which gives us
     * 129 pixels of scroll
     */
    const MAGIC_SCROLL_PIXELS = window.innerWidth > 600 ? 92 : 72;
    const scrollYTo = getScrollPixels(scrollToTarget, MAGIC_SCROLL_PIXELS);
    if (categoryName) {
      trackEvent(`home_category_${categoryName.toLowerCase()}_click`);
      navigateTop(`/popular/${categoryName}`,{ scrollYTo });
    }
  }, [trackEvent])


  // Newly Added Product Data (since last session)
  const { newlyAddedSinceDate, newlyAddedByCategory } = useNewlyAddedProducts();

  const scrollToTarget = React.useRef();

  return (
    <BodyWrapper pageLayout={LayoutTypes.Default}>
      <PageTitle title="Shop Greater Boston Cannabis Delivery" />
      <ServiceMessages />
      <ReferredSessionBanner />
      <VerifyCartReminderProducts />
      <PromoText />
      <div className={styles.headerWrap}>
        <Header
          size={HeaderSize.Medium_Static}
          withStyle={{marginBottom:'.5rem'}}>
          <span className={styles.headerGrid}>
            <span className={styles.headerText}>Shop by Product</span>
            <span className={styles.headerSearchWrapper}
                  style={{zIndex:STACKING.HOME_SEARCH_WIDGET}}>
              <SearchTypeAhead placeholder="Search Zyp Run" />
            </span>
          </span>
        </Header>
      </div>
      <div className={styles.contentWrap}>
        { productCategories?.length
          ? <>
              <div data-is-scroll-target="true" ref={scrollToTarget} style={{height:0}} ></div>
              <ProductFilterCarousel
                currentCategory={currentCategory}
                enabledCategories={productCategories}
                handleClick={category => filterClick(category.display_name)}
                isHomePage />
              <SaleItemsProvider>
                <CurrentTimeSlotProvider>
                  { currentCategory === dealsCategory.display_name
                    ? <DealsPage />
                    : currentCategory
                      ? <>
                          <SubCategoryCarousels categoryName={currentCategory} />
                          <BrandCarousels categoryName={currentCategory} />
                          <NewThisSessionCarousel
                             categoryName={currentCategory}
                             newlyAddedSinceDate={newlyAddedSinceDate}
                             newlyAddedByCategory={newlyAddedByCategory} />
                          <BundleDealLinksForCategory categoryName={currentCategory} />
                        </>
                      : <>
                          {/* Home page */}
                          <PromoBannerSection />
                          <ReOrderSection />
                          <PreviewProducts headerPrefix={`Popular`} />
                          <NewlyAddedLinks
                            newlyAddedSinceDate={newlyAddedSinceDate}
                            newlyAddedByCategory={newlyAddedByCategory} />
                          <KlaviyoSubscribeModal />
                        </>
                  }
                </CurrentTimeSlotProvider>
              </SaleItemsProvider>
              <ProductRequestAccordion
                currentCategory={currentCategory}
                productCategories={productCategories} />
            </>
          : <Spinner />
        }
      </div>
    </BodyWrapper>
  )
}

export default Home;
