import * as React from 'react';
import PropTypes from 'prop-types';

import styles from './LabResults.module.css';

// Sometimes the WH gets lazy with the strain_name in Dutchie
export const NOT_A_STRAIN = ['1t1', '2t1', '3t1', 'hybrid',
  'indica', 'sativa', 'indica-hybrid', 'sativa-hybrid', 'cbd', 'thc'];

const DisplayStrainName = ({
  strain_name,
}) => {

  return (
    strain_name && !NOT_A_STRAIN.includes(strain_name.toLowerCase())
      ? <div className={styles.strainWrap}>
          Strain: <span className={styles.strain}>{strain_name}</span>
        </div>
      : null
  )
}

DisplayStrainName.propTypes = {
  strain_name: PropTypes.string,
}

export default  DisplayStrainName;
