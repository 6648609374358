import * as React from 'react';

import useIntersectionObserver from '../util/useIntersectionObserver';
import { navigateTop } from '../routing/router-utils';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import CategoryImage, { popularCategory, dealsCategory } from '../common/CategoryImage';
import { addMobileScrollHandler, removeMobileScrollHandler } from '../util/mobile-utils';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import BackIcon from '../icons/BackIcon';
import PropTypes from 'prop-types';

import styles from './ProductFilter.module.css';

// Render image and hover-state image
const CategoryImageSet = ({category}) => (
  <>
    <span className={styles.inactive}>
      <CategoryImage categoryObj={category} />
    </span>
    <span className={styles.active}>
      <CategoryImage isActive categoryObj={category} />
    </span>
  </>
);

/**
 * Product category carousel
 *
 * @param {*} currentCategory - initial category
 * @param {array} enabledCategories - to specify a category subset
 * @param {function} handleClick - handle category click
 * @param {boolean} isHomePage - handle homepage navigation
 */
const ProductFilterCarousel = ({
  currentCategory,
  enabledCategories,
  handleClick,
  backLink,
  isHomePage
}) => {

  const { activeCategories:productCategories } = React.useContext(ProductPreviewContext);

  /**
   * Horizontal Filter Scroll
   *
   * Hide/show Desktop nav arrows on hover as needed
   */
  const firstItemObserverRef = React.useRef();
  const lastItemObserverRef = React.useRef();
  const { isIntersecting:isScrollStart } = useIntersectionObserver(firstItemObserverRef);
  const { isIntersecting:isScrollEnd } = useIntersectionObserver(lastItemObserverRef);
  React.useEffect(() => {
    const wrapper = filterRef.current;
    if (wrapper) {
      wrapper.classList.toggle(styles.withLeftArrow, !isScrollStart);
      wrapper.classList.toggle(styles.withRightArrow, !isScrollEnd);
    }
  }, [isScrollStart, isScrollEnd])

  // For brand pages mostly
  const [categorySubSet, setCategorySubSet] = React.useState();
  React.useEffect(() => {
    if (enabledCategories) {
      setCategorySubSet(enabledCategories);
    }
  }, [productCategories, enabledCategories])

  // Debounce the scroll handling
  const handlerRef = React.useRef();
  // const verticalScrollRef = React.useRef();

  // Selected category for scroll-into-view
  const selectedItemRef = React.useRef();

  React.useEffect(() => {
    const handleScroll = () => {
      if (handlerRef.current) {
        window.clearTimeout(handlerRef.current);
      }

      // Do not change filter state when modal is open and document is frozen
      if (document.body.getAttribute('data-modal-open') === "true") {
        return;
      }

      // Keep selected in view after transition but scope
      // to vertical scroll only due to mobile issues.
      const makeSelectedVisible = () => {
        // TODO: FIXME, This is buggy in Mobile
        return;
        /*
        if (window.scrollY !== verticalScrollRef.current &&
            selectedItemRef.current?.scrollIntoViewIfNeeded) {
          selectedItemRef.current.scrollIntoViewIfNeeded({inline:'start'});
          verticalScrollRef.current = window.screenY;
        }
        */
      };

      handlerRef.current = window.setTimeout(() => {
        if (filterRef.current) {
          /**
           * Carousel height animation on scroll
           *
           * Full height filter = 118px or 80px mobile
           * Collapsed filter = 60px
           *
           * Scroll fudge factor = 118 - 60 or 58;
           * (To avoid infinite animation)
           */
          const isSticky =  filterRef.current.getAttribute('data-is-sticky') === "true";
          const scroll = window.scrollY;
          const filterHeightDiff = 58;
          if (!isSticky && scroll > filterHeightDiff) {
            // console.log(`trigger sticky: scroll: ${scroll}`);
            filterRef.current.setAttribute('data-is-sticky', true);
            makeSelectedVisible();
         // When filter goes sticky we lose 58px of scroll
          } else if (isSticky && scroll > 0) {
            // console.log(`keep sticky: scroll: ${scroll}`);
            filterRef.current.setAttribute('data-is-sticky', true);
          } else {
            // console.log(`trigger static: scroll: ${scroll}`);
            filterRef.current.setAttribute('data-is-sticky', false);
            makeSelectedVisible();
          }
        }
      }, 200); // delay needed on category change
    }
    document.addEventListener('scroll', handleScroll);
    addMobileScrollHandler(handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
      removeMobileScrollHandler(handleScroll);
    };
  }, []);

  const filterRef = React.useRef();
  const carouselRef = React.useRef();

  let animateTask = React.createRef();
  // A simple timeout-based animation
  const animateScroll = (scrollAmount) => {
    const carousel = carouselRef.current;
    let scrollProgress = 0;
    // We'll scroll 20px at a time
    const roundedAmount = Math.floor(scrollAmount/20) * 20;
    window.clearTimeout(animateTask.current);
    if (carousel) {
      const scrollMore = () => {
        const amount = scrollAmount < 0 ? -20 : 20;
        carousel.scrollBy (amount, 0);
        scrollProgress += 20;
	      if (scrollProgress <= Math.abs(roundedAmount)) {
          animateTask.current = window.setTimeout(scrollMore, 15);
        }
      }
      // initiate scroll
      scrollMore();
    }
  }

  // Handle arrow click/tap
  const scrollItems = (direction) => {
    const carousel = carouselRef.current;
    if (carousel) {
      const currentLeft = carousel.scrollLeft;
      const scrollAmount = parseInt(window.innerWidth * .8, 10);
      if (direction < 0) {
        // scroll back some or to 0
        animateScroll(Math.min(scrollAmount, currentLeft) * -1);
      } else {
        // scroll foward some or to end
        const more = currentLeft + scrollAmount;
        const max = carousel.scrollWidth - carousel.clientWidth;
        animateScroll(Math.min(more, max));
      }
    }
  }


  // Back link behavior depending on context
  const navigateBack = React.useCallback(() => {
    /**
     * On the /products page the Home icon links
     * to the related home category carousel: /Edibles
     * The backLink is used by the brand home page
     */
    if (backLink) {
      navigateTop(backLink);
    } else {
      const homePath = isHomePage ? '/' : `/popular/${currentCategory}`;
      navigateTop(homePath);
    }
  }, [backLink, isHomePage, currentCategory]);

  // On the brand page (categorySubset) we align the 1 or 2 categories to the left
  const fullSetStyle = categorySubSet?.length < 6 ? {} : { justifyContent:'space-between' };

  return (
    <div ref={filterRef} id="category_filter" style={fullSetStyle} className={styles.carouselOuter}>
      <div className={styles.controlsWrap}>
        <span className={`${styles.scrollArrow} ${styles.scrollLft}`} onClick={() => scrollItems(-1)}><ChevronLeftRoundedIcon /></span>
        <span className={`${styles.scrollArrow} ${styles.scrollRgt}`} onClick={() => scrollItems(1)}><ChevronRightRoundedIcon /></span>
      </div>

      <div className={styles.carouselWrap}>
        {/* blur the edge of first/last items */}
        <div className={`${styles.itemMask} ${styles.firstItemMask}`}></div>
        <div className={`${styles.itemMask} ${styles.lastItemMask}`}></div>

        <ul ref={carouselRef} className={styles.carousel}>
          <li key={`link_home`}
              id="category_home_link"
              onClick={navigateBack}
              ref={
                !currentCategory
                ? selectedItemRef
                : null
              }
              className={`
                ${styles.carouselItem}
                ${!currentCategory ? styles.selectedCategory : ''}
              `} data-val="home">
            <div className={styles.homeIcon}>
              { isHomePage
                ? <CategoryImageSet category={popularCategory} />
                : <BackIcon size="50px" />
              }
            </div>
            { isHomePage
              ? <span className={styles.label}>Popular</span>
              : <span className={styles.label}>Back</span>
            }
            <div className={`${styles.pxl} ${styles.scrollXPixelLeft}`} ref={firstItemObserverRef} />
          </li>

          {/* subSet for brand pages */}
          { (categorySubSet || productCategories)?.map((category, idx) => {
            const selectedStyle = currentCategory === category.display_name ? styles.selectedCategory : '';
            return (
              <li key={`link${category.name}`}
                  onClick={()=> {
                    /* update category */
                    handleClick(category);
                  }}
                  ref={
                    selectedStyle
                    ? selectedItemRef
                    : null
                  }
                  className={`${styles.carouselItem} ${selectedStyle}`}
                  data-val={category.name}>
                <CategoryImageSet category={category} />
                <span className={styles.label}>
                  {category.display_name}
                </span>
                { (!isHomePage && idx === (categorySubSet || productCategories).length - 1) &&
                  <div className={`${styles.pxl} ${styles.scrollXPixelRight}`} ref={lastItemObserverRef} />
                }
              </li>
            )
          }) }
          { isHomePage &&
            <li key={`link${dealsCategory.name}}`}
                onClick={() => {
                  handleClick(dealsCategory)
                }}
                className={`${styles.carouselItem} ${currentCategory === dealsCategory.display_name ? styles.selectedCategory : ''}`}>
              <CategoryImageSet category={dealsCategory} />
              <span className={styles.label}>{dealsCategory.display_name}</span>
              <div className={`${styles.pxl} ${styles.scrollXPixelRight}`} ref={lastItemObserverRef} />
            </li>
          }
        </ul>
      </div>
    </div>
  )
}

ProductFilterCarousel.propTypes = {
  currentCategory: PropTypes.string,
  enabledCategories: PropTypes.arrayOf(Object),
  handleClick: PropTypes.func.isRequired,
  backLink: PropTypes.string,
  isHomePage: PropTypes.bool
}

export default ProductFilterCarousel;
