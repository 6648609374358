import * as React from 'react';
import Header, { HeaderSize } from '../styleguide/Header';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import { NOT_A_STRAIN } from '../products/DisplayStrainName';
// import { STRAINS } from '../products/strains';

const isSativaLeaning = (desc) => {
  return /hybrid/i.test(desc) && /sativa/i.test(desc) && /leaning/.test(desc);
}
const isIndicaLeaning = (desc) => {
  return /hybrid/i.test(desc) && /indica/i.test(desc) && /leaning/.test(desc);
}
const isMisLabeledType = (product, type) => {
  const { description, cannabis_type } = product.display_info;
  return cannabis_type === type && (isIndicaLeaning(description) || isSativaLeaning(description));
}
const isCannabisType = (product, type) => {
  const { description, cannabis_type } = product.display_info;
  return description && cannabis_type === type;
}

const validStrains = (products) => {
  let missing = new Map();
  const filtered = products.filter(product => {
    const { strain_name } = product.display_info;
    if (strain_name && !NOT_A_STRAIN.includes(strain_name.toLowerCase())) {
      return strain_name;
    } else {
      const name = product.display_info.name.split(' - ')[0].replace(/\s+\dpk/, '');
      //if (STRAINS.includes(name)) {
        if (product.is_cannabis) {
          const count = missing.get(name) || 0;
          missing.set(name, count + 1);
        }
        return false;
      //}
    }
  });
  Array.from(missing.keys()).forEach(key => {
    const count = missing.get(key);
    if (count > 1) {
      console.log(`Set strain: ${key}: ${missing.get(key)}`);
    }
  })
  console.log(missing.keys().length);

  // Strain name adjustments
  products.forEach(product => {
    const { description, strain_name, name } = product.display_info;
    if (isCannabisType(product, 'Hybrid') && isIndicaLeaning(description)) {
      console.log(`Hybrid is Indica leaning: ${name}: ${strain_name}`);
    }
    if (isMisLabeledType(product, 'Indica')) {
      console.log(`Indica is Indica leaning: ${name}: ${strain_name}`);
    }
    if (isCannabisType(product, 'Hybrid') && isSativaLeaning(description)) {
      console.log(`Hybrid is Sativa leaning: ${name}: ${strain_name}`);
    }
    if (isMisLabeledType(product, 'Sativa')) {
      console.log(`Sativa is Sativa leaning: ${name}: ${strain_name}`);
    }
  })
  return filtered;
}

const totalTerpeneCount = (products) => {
  const filtered = products.filter(product => {
    const { lab_results } = product.display_info || {};
    return lab_results?.find(result => result.labTest === 'TotalTerpenes');
  });
  return filtered.length;
}

const cannabisProductCount = (products) => {
  const cannabis = products.filter(product => product.is_cannabis);
  return cannabis.length;
}

const flowerProductCount = (products) => {
  const flower = products.filter(product => product.display_info.category?.name === 'flower');
  return flower.length;
}

const ProductDataStats = () => {

  const { allProducts } = React.useContext(ProductPreviewContext);

  const [strainCoverage, setStrainCoverage] = React.useState();
  const [strainCoverageFlower, setStrainCoverageFlower] = React.useState();
  const [terpeneCoverage, setTerpeneCoverage] = React.useState();

  React.useEffect(() => {
    if (allProducts?.length) {
      const cannabisCount = cannabisProductCount(allProducts);
      const flowerCount = flowerProductCount(allProducts);
      setStrainCoverage(
        Math.floor(
         (validStrains(allProducts).length/cannabisCount) * 100
        )
      )
      const flowerStrains = validStrains(allProducts).filter(product => product.display_info.category.name === 'flower');
      setStrainCoverageFlower(
        Math.floor(
         (flowerStrains.length/flowerCount) * 100
        )
      )
      setTerpeneCoverage(
        Math.floor(
         (totalTerpeneCount(allProducts)/cannabisCount) * 100
        )
      )
    }
  }, [allProducts])

  return (
    <BodyWrapper pageLayout={LayoutTypes.Narrow}>
      <Header size={HeaderSize.Medium_Static} withStyle={{margin:'3rem 0 2rem'}} isCentered>
        Product Data Stats
      </Header>
      <div style={{width:600, margin: '1rem auto', fontSize:'20px'}}>
        { typeof strainCoverage === "number" &&
          <div>
            Strain name coverage: {strainCoverage}%
          </div>
        }
        { typeof strainCoverageFlower === "number" &&
          <div>
            Strain name coverage (Flower): {strainCoverageFlower}%
          </div>
        }
        { typeof terpeneCoverage === "number" &&
          <div>
            Total terpenes coverage: {terpeneCoverage}%
          </div>
        }
      </div>
    </BodyWrapper>
  )
}

export default  ProductDataStats;
