import React from 'react';

import useAISummary from './useAISummary';
import Alert from '@material-ui/lab/Alert';
import Spinner from '../common/Spinner';
import AISummaryTooltip from './AISummaryTooltip';

import styles from './StrainDescription.module.css';


const AISummary = ({product}) => {

  const { dutchie_sku } = product;
  const { strain_name } = product.display_info;

  const { strainDescription, loading } = useAISummary(dutchie_sku, strain_name);

  return (
    loading
      ? <div style={{padding:'2rem 0', minHeight:300}}>
          <Spinner inSmallLayout color={'#000'} size={48} />
        </div>
      : strainDescription
        ? <>
            <div className={styles.strainDescription} dangerouslySetInnerHTML={{ __html:`${strainDescription}`}} />
            <AISummaryTooltip inModal={true} />
          </>
        : <Alert severity='info'>
            Sorry, a strain summary is not currently available...
          </Alert>
  );
}

export default AISummary;
