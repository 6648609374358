import * as React from 'react';

import SubCategoryAndWeight from './SubCategoryAndWeight';
import DisplayStrainName from './DisplayStrainName';
import CbdIcon from '../../assets/cbdIcon.svg';
import HybridIcon from '../../assets/hybridIcon.svg';
import IndicaIcon from '../../assets/indicaIcon.svg';
import SativaIcon from '../../assets/sativaIcon.svg';

import PropTypes from 'prop-types';

import styles from './LabResults.module.css';

// Known Leaf Logix values
export const unitSymbolMap = new Map([
  ['Percentage','%'],
  ['Milligrams', 'mg'],
  ['MilligramsPerGram', 'mg/g']
]);

export const TypeIcons = {
  CBD: CbdIcon,
  Hybrid: HybridIcon,
  Indica: IndicaIcon,
  'Indica-Hybrid': IndicaIcon,
  Sativa: SativaIcon,
  'Sativa-Hybrid': SativaIcon,
};

export const TypeColors = {
  CBD: '#8239bf',                  // 6.38 to 1 purple
  Hybrid: '#32A280',               // 3.17 to 1 green
  Indica: 'var(--zrPrimaryDark)',  // 4.6 to 1 blue
  'Indica-Hybrid': '#3a807b',      // dark blue-green
  Sativa: '#f06100',               // 3.27 to 1 orange
  'Sativa-Hybrid': '#a27132',      // gold
  DEFAULT: '#000'                  // black
}

export const TypeBackgroundColors = {
  CBD: '#ddc6f1',               // purple
  Hybrid: '#32A28044',          // green
  Indica: '#d5e9fd',            // blue
  'Indica-Hybrid': '#32A28044', // green
  Sativa: '#ff67005f',          // orange
  'Sativa-Hybrid': '#32A28044', // green
  DEFAULT: '#f0f0f0',           // gray
}

/**
 * Spec for what to show on cards is here:
 * https://app.shortcut.com/zip-run/story/4553/web-app-product-card-testing-information-cards-detail
 */
// On  the modal we only show certain lab results
const CANNABINOID_WHITE_LIST = ['TAC', 'THC', 'THCV', 'CBC', 'CBD', 'CBDV', 'CBG', 'CBN', 'TotalTerpenes'];
// const TOTAL_TERPS_DISPLAY_MIN_PCT = 2;

const labTestLabel = (test) => {
  if (test === 'TotalTerpenes') {
    return 'Total Terpenes';
  }
  return test;
}

const LabResults = ({
  productId,
  display_info,
  grams
}) => {

  const {
    lab_results,
    strain_name,
    cannabis_type,
    category,
    sub_category,
  } = display_info || {};

  const [typeIcon, setTypeIcon] = React.useState();
  React.useEffect(() => {
    if (TypeIcons[cannabis_type]) {
      setTypeIcon(TypeIcons[cannabis_type])
    }
  }, [cannabis_type])

  const [testResults, setTestResults] = React.useState();

  React.useEffect(() => {
    const results = [];
    // Using white list for desired display order
    CANNABINOID_WHITE_LIST.forEach(enabledTest => {
      const result = lab_results?.find(item => item.labTest === enabledTest) || undefined;
      if (result && result.value > 0) {
        results.push(result);
      }
    })
    setTestResults(results);
  },[lab_results])

  return (
    <>
      <DisplayStrainName strain_name={strain_name} />

      <div className={styles.typeWrap}>
        <span>{cannabis_type}</span>
        { typeIcon &&
          <img alt={cannabis_type} className={styles.typeIcon} src={typeIcon} />
        }
      </div>

      <SubCategoryAndWeight
        category={category}
        subCategory={sub_category}
        grams={grams} />

      { testResults?.length > 0
        ?
          <>
            <ol className={styles.compounds}>
              { testResults.map(result => {
                  return (
                    <li key={`${productId}-${result.labTest}`} className={result.labTest === 'TotalTerpenes' ? styles.totalTerpines : ''}>
                      <div className={styles.compound}>
                        {result.value}{unitSymbolMap.get(result.labResultUnit)}
                        <div className={styles.compoundLabel}>
                          {labTestLabel(result.labTest)}*
                        </div>
                      </div>
                    </li>
                  )
                })
              }
            </ol>
            <div className={styles.disclaimor}>*Amounts are averages, individual items may vary.</div>
          </>
        : null
      }
    </>
  )
}

LabResults.propTypes = {
  productId: PropTypes.string.isRequired,
  display_info: PropTypes.shape({
    lab_results: PropTypes.array,
    strain_name: PropTypes.string,
    cannabis_type: PropTypes.string,
    category: PropTypes.object.isRequired,
    sub_category: PropTypes.object,
  }).isRequired,
  grams: PropTypes.number,
}

export default  LabResults;
