import * as React from 'react';

import { trackError } from '../analytics/tracking';
import { dispensaryProductsEndpoint } from '../../constants/api-endpoints';

// A fetch hook for use in the brand/product provider
function useProductsFetch(user, vendorId) {

  const [data, setData] = React.useState();
  const [loading, setLoading] = React.useState();
  const [error, setError] = React.useState();

  const controllerRef = React.useRef(new AbortController());

  React.useEffect(() => {

    const controller = controllerRef.current.signal.aborted
      ? new AbortController()
      : controllerRef.current;

    if (user) {
      user.getIdToken().then(idToken => {
        setLoading(true);
        setData(null);
        setError(null);
        fetch(`${dispensaryProductsEndpoint}${vendorId ? `?vendor_id=${vendorId}` : ''}`,
          {
            method: 'GET',
            headers: {
              'Authorization': idToken
            },
            Accept: 'application/json',
            signal: controller.signal
          })
        .then(response => response.json())
        .then(json => {
          setLoading(false);
          json && setData(json.data);
        })
        .catch(err => {
          setLoading(false)
          setError('An error occurred...')
          trackError('error_fetching_product_inventory');
        })
      })
    }
    return () => {
      controller.abort();
    }
  }, [user, vendorId])

  return {
    data,
    loading,
    error
  }
}

export default useProductsFetch;
