import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { navigateTopWithState } from '../routing/router-utils';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import Header, { HeaderSize } from '../styleguide/Header';
import PropTypes from 'prop-types';

import styles from './BundleDealsPage.module.css';

const DEAL_CALLOUT_TRACKING_PREFIX = 'mixmatch_callout';

const BundleDealLinksForCategory = ({
  categoryName
}) => {

  const { bundleInfoByCategory } = React.useContext(ProductPreviewContext);
  const categoryDeals = bundleInfoByCategory?.get(categoryName);

  // REDUCE Logging for better firebase dashboard performance
  // React.useEffect(() => {
  //  if (categoryDeals) {
  //    trackEvent(`${DEAL_CALLOUT_TRACKING_PREFIX}_${categoryName}_display`);
  //  }
  // }, [categoryName, categoryDeals])

  const handleClick = React.useCallback((bundleId) => {
    trackEvent(`${DEAL_CALLOUT_TRACKING_PREFIX}_${categoryName}_clk`);
    navigateTopWithState(
      `/mixMatch${bundleId ? `/${bundleId}` : ''}`,
      { returnToPath: `${window.location.pathname}`}
    )
  },[categoryName])

  return ( categoryDeals
    ? <div className={styles.categoryBundleCallout}>
        <Header
          size={HeaderSize.Medium_Static}
          withClass={styles.calloutHeader}>
          Mix & Match
        </Header>
        <div className={styles.calloutMessage}>
          Shop Today's Bulk {categoryName} Discounts!
        </div>
        <ul className={styles.bundleLinks}>
          { categoryDeals.map(bundleInfo => {
            const { bundleId, teaserText } = bundleInfo;
            return (
              <li key={bundleId}
                  className={styles.bundleLink}
                  onClick={() => handleClick(bundleId)}>
                {teaserText}
              </li>
            )
          })}
          { categoryDeals.length > 1
            ? <li key="allBundles"
                className={styles.bundleLink}
                onClick={() => handleClick()}>
                Shop All
              </li>
            : null
          }
        </ul>
      </div>
    : null
  )
}

BundleDealLinksForCategory.propTypes = {
  categoryName: PropTypes.string.isRequired,
}

export default BundleDealLinksForCategory;
