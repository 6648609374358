import * as React from 'react';

import { devLog } from '../util/util';
import { trackEvent, trackingString } from '../analytics/tracking';
import { ORDER_STATUS } from './useCustomerOrders';
import { UserContext } from '../providers/UserProvider';
import { NotificationsContext }  from '../notifications/NotificationsProvider';
import { ProductCategoryContext } from '../providers/ProductCategoryProvider';
import { getSortPrice } from '../products/DisplayPrice';

/**
 * The current spec for category order optimization by category spend:
 *   Order Count at least 2
 *   Accessories spend is set to -1 to make it last
 *
 * Return undefined when not optimizing (insufficient orders)
 */

const REQUIRED_ORDER_MIN = 2;
const ACCESSORIES_DISPLAY_NAME = 'Accessories';
const LEGACY_MUNCHIES__DISPLAY_NAME = 'Munchies';

const useCategoriesByCustomerSpend = () => {

  const { user } = React.useContext(UserContext);
  const { priorOrders } = React.useContext(NotificationsContext);
  const { productCategories } = React.useContext(ProductCategoryContext);

  const [categoriesBySpend, setCategoriesBySpend] = React.useState();
  const [totalSpendCents, setTotalSpendCents] = React.useState();

  // We're tracking the first optimized category + it's position
  const logFirstOptimizedCategory = React.useCallback((availableCategoriesBySpend) => {
    let index = 0;
    while (productCategories[index].display_name ===
           availableCategoriesBySpend[index].display_name) {
      index += 1;
    }
    trackEvent(`categories_optimized_${index + 1}_${trackingString(availableCategoriesBySpend[index].display_name)}`);
  }, [productCategories])

  React.useEffect(() => {
    if (productCategories?.length && priorOrders?.length >= REQUIRED_ORDER_MIN) {
      const categorySpend = new Map();
      const completed = priorOrders.filter(order => order.status === ORDER_STATUS.COMPLETED);
      let totalSpendCents = 0;
      completed.forEach(order => {
        order.items.forEach(item => {
          const { display_name } = item.display_info.category || {};
          if (display_name) {
            const spend = getSortPrice(item) * item.quantity_requested;
            const total = categorySpend.get(display_name) || 0;
            categorySpend.set(display_name, total + spend);
            totalSpendCents += spend;
          }
        });
      })
      // Force Accessories to the end
      categorySpend.set(ACCESSORIES_DISPLAY_NAME, -1);
      // Remove Munchies
      categorySpend.delete(LEGACY_MUNCHIES__DISPLAY_NAME);

      // Only provide currently available categories
      const categorySpendArray = Array.from(categorySpend);
      const availableCategoriesBySpend = [ ...productCategories].map(category => {
        const spendItem = categorySpendArray.find(cat => cat[0] === category.display_name);
        category.totalSpend = spendItem ? spendItem[1] : 0;
        return category;
      }).sort((a,b) => b.totalSpend - a.totalSpend);

      setCategoriesBySpend(availableCategoriesBySpend);
      setTotalSpendCents(totalSpendCents);
      logFirstOptimizedCategory(availableCategoriesBySpend);
      devLog(`useCategoriesByCustomerSpend`);
    }
  }, [user, priorOrders, productCategories, logFirstOptimizedCategory]);

  return {
    categoriesBySpend,
    totalSpendCents
  };
}

export default useCategoriesByCustomerSpend;
