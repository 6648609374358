import * as React from 'react';

import { groupByStandardCategories } from './product-utils';
import { optimizeCategoryKeys } from './category-optimization-utils';

const useProductsByCategory = (products, categoriesBySpend) => {
  const [ productData, setProductData ] = React.useState();
  React.useEffect(() => {
    if (products) {
      const result = groupByStandardCategories(products);
      if (categoriesBySpend) {
        result.productsByCategory = optimizeCategoryKeys(result.productsByCategory, categoriesBySpend);
      }
      setProductData(result);
    }
  }, [products, categoriesBySpend])

  return {
    productsByCategory: productData?.productsByCategory,
    typesByCategory: productData?.typesByCategory,
    sessionProductSkus: productData?.sessionProductSkus,
  }
}

export default useProductsByCategory;
