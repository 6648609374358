import * as React from 'react';

import { GRAMS_TO_DISPLAY_OUNCES, WEIGHT_ENABLED_CATEGORIES } from './ProductWeightPill';
import PropTypes from 'prop-types';

import styles from './ProductDetailsCard.module.css';

const SubCategoryAndWeight = ({grams, category, subCategory}) => {

  const [displayWeight, setDisplayWeight] = React.useState();
  const [displaySubCategory, setDisplaySubcategory] = React.useState();

  React.useEffect(() => {
    if (grams && typeof displayWeight === 'undefined') {
      if (GRAMS_TO_DISPLAY_OUNCES.has(grams.toString())) {
        setDisplayWeight(GRAMS_TO_DISPLAY_OUNCES.get(grams.toString()));
      } else {
        setDisplayWeight(`${grams}g`);
      }
    }
    if (!displaySubCategory) {
      setDisplaySubcategory(subCategory?.display_name || category?.display_name);
    }
  }, [category, subCategory, grams, displayWeight, displaySubCategory]);

  return ( WEIGHT_ENABLED_CATEGORIES.includes(category?.name) &&
           displaySubCategory &&
           displayWeight
            ? <div className={styles.subCategoryLabel}>
                <span>{ displaySubCategory }</span>
                <span>-</span>
                <span>{displayWeight}</span>
              </div>
            : null
  )
}

SubCategoryAndWeight.propTypes = {
  grams: PropTypes.number,
  category: PropTypes.object,
  subCategory: PropTypes.object
}

export default  SubCategoryAndWeight;
