import * as React from 'react';

import { BoltIcon } from './CurrentSlotETAText';

// Priority ETA label: "Priority Delivery by 3:15pm" styled via classes prop
const PrioritySlotETAText = ({
  slotLabel,
  classes={},
}) => {
  return (
    <div className={`${classes.deliveryByWrap} ${classes.priorityByWrap}`}>
      <BoltIcon className={classes.boltIcon} />
      <span className={classes.deliveryText}>
        Priority Delivery by
      </span>
      {' '}
      <span className={classes.timeLabel}>
        {slotLabel}!
      </span>
    </div>
  )
}

export default PrioritySlotETAText;
