import * as React from "react";

import { devLog } from "../util/util";
import { useAnalyticsStore } from "../../App";
import { UserContext } from '../providers/UserProvider';
import { ProductCategoryContext } from "../providers/ProductCategoryProvider";
import useProductsByCategory from "./useProductsByCategory";
import useCategoriesByCustomerSpend from '../orders/useCategoriesByCustomerSpend';
import { mapBundleDetailsByCategoryName } from "../deals/deal-utils";
import useProductsFetch from "../util/useProductsFetch";
import { fetchProductDetail } from "../dispensary/dispensary-utils";

export const ProductPreviewContext = React.createContext({});
/**
 * This provider is used for menu home product carousels
 *
 * Despite its name, this provider loads ALL the products
 */
const ProductPreviewProvider = ({children}) => {

  const { user } = React.useContext(UserContext);
  const { trackError } = useAnalyticsStore();
  const { productCategories } = React.useContext(ProductCategoryContext);
  const { data:products, loading } = useProductsFetch(user);

  // All products
  const [allProducts, setAllProducts] = React.useState();
  const { categoriesBySpend } = useCategoriesByCustomerSpend();
  const { productsByCategory, typesByCategory, sessionProductSkus } = useProductsByCategory(products, categoriesBySpend);
  const [bundleInfoByCategory, setBundleInfoByCategory] = React.useState();
  // Categories for which we have products
  const [activeCategories, setActiveCategories] = React.useState();

  // Currently used for search and order submission errors
  const getCachedProductById = React.useCallback((id) => {
    const product = allProducts?.length
      ? allProducts.find(product => product.id === id)
      : undefined;
    return product
      // return clone: .quantity mutation!
      ? Object.assign({}, product)
      : undefined;
  }, [allProducts]);

    // Currently used for cart restore
    const getCachedProductBySku = React.useCallback((sku) => {
      const product = allProducts?.length
        ? allProducts.find(product => product.dutchie_sku === sku)
        : undefined;
      return product
        // return clone: .quantity mutation!
        ? Object.assign({}, product)
        : undefined;
    }, [allProducts]);

  // CartItem hits this 3X so we'll debounce the extra requests
  const requestRef = React.useRef();
  const getProductAvailability = React.useCallback((itemId, callback, controller) => {
    const wrappedCb = (response) => {
      requestRef.current = false;
      callback(response);
    }
    if (user && callback && !requestRef.current) {
      try {
        devLog('fetching product availability');
        requestRef.current = true;
        fetchProductDetail(itemId, wrappedCb, controller);
      } catch(error) {
        devLog(`useProductDetails fetch error ${error}`);
        requestRef.current = false;
        trackError('get_product_availability_error');
      }
    }
  }, [user, trackError]);

  React.useEffect(() => {
    if (products) {
      setAllProducts(products);
      setBundleInfoByCategory(mapBundleDetailsByCategoryName(products));
    }
  } , [products]);

  React.useEffect(() => {
    if (user && productCategories && productsByCategory) {
      devLog('ProductPreviewProvider setup!');
      // For category tab display
      if (user.isAnonymous) {
        setActiveCategories(productCategories.filter(
          category => productsByCategory.has(category.display_name)
        ))
      } else {
        setActiveCategories(
          categoriesBySpend
           ? categoriesBySpend.filter(category => productsByCategory.has(category.display_name))
           : productCategories.filter(category => productsByCategory.has(category.display_name))
        )
      }
    }
  }, [user, productCategories, categoriesBySpend, productsByCategory]);

  return (
    <ProductPreviewContext.Provider value={{
      allProducts,
      productsByCategory,
      typesByCategory,
      bundleInfoByCategory,
      sessionProductSkus,
      activeCategories,
      getCachedProductById,
      getCachedProductBySku,
      getProductAvailability,
      loading
    }}>
      {children}
    </ProductPreviewContext.Provider>
  );
};

export default ProductPreviewProvider;
