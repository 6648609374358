import React from 'react'

import { useAnalyticsStore } from '../../App';
import useOnWidthResize from '../util/useOnWidthResize';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import PropTypes from 'prop-types'

import styles from './SimpleCarousel.module.css';

// For styling a carousel via className
export const CarouselStyle = {
  PRODUCT_CATEGORIES: styles.categories,
  PRODUCT_CARDS: styles.productCards,
  SUGGESTED_PRODUCTS: styles.suggestedProducts,
  CART_SUGGESTED_PRODUCTS: styles.cartSuggestedProducts,
  PROMO_BANNERS: styles.promos
};

/**
 * A manual carousel of component items with arrow controls.
 * Can be scrolled with controls or via touch
 */
const SimpleCarousel = ({
  CarouselComponent,  // For each carousel item
  carouselItemProps,
  maxItemCount=4,
  trackingLabel="simple",
  withStyle={},
  carouselStyle,
  itemClass,
  MoreItemsCard,
  isSponsored,
  onCarouselScroll,        // To load offscreen product card images
  carouselHasScrolled,     // To load offscreen product card images
  scrollResetDependency,   // Scroll back to start on filter change etc.
}) => {

  const { trackEvent } = useAnalyticsStore();

  const controlsRef = React.useRef();
  const carouselRef = React.useRef();

  // Enforce max count
  const itemProps = carouselItemProps?.slice(0, maxItemCount);

  let animateTask = React.createRef();

  // How fast to scroll
  const scrollPixels = 33;
  // Item spacing: .carouselItem margin
  const itemSpacing = 16;

  // A simple timeout-based animation
  const animateScroll = (scrollAmount) => {
    // How much we've scrolled to target anount
    let scrollProgress = 0;
    window.clearTimeout(animateTask.current);

    const carousel = carouselRef.current;
    if (carousel) {
      const scrollMore = () => {
        // We have to adjust the last animation pixel count to match the target scroll amount
        let adjustedScrollPixels = scrollAmount < 0 ? -1 * scrollPixels : scrollPixels;
        // If we're about to overshoot the scroll target
        if ((scrollProgress + scrollPixels) >  Math.abs(scrollAmount)) {
          if (scrollAmount > 0) {
            adjustedScrollPixels = scrollAmount - scrollProgress;
          } else {
            adjustedScrollPixels = scrollAmount + scrollProgress;
          }
        }
        carousel.scrollBy(adjustedScrollPixels, 0);
        scrollProgress += scrollPixels;
	      if (scrollProgress < Math.abs(scrollAmount)) {
          animateTask.current = window.setTimeout(scrollMore, 15);
        }
      }
      // initiate scroll
      scrollMore();
    }
  }

  // Handle arrow click/tap
  const scrollItems = (direction) => {
    const itemWidth = controlsRef.current.clientWidth + itemSpacing;
    const carousel = carouselRef.current;
    if (carousel) {
      const currentLeft = carousel.scrollLeft;
      // I'm using the controls elem width to determine contentWith
      const scrollAmount = parseInt(itemWidth, 10);
      if (direction < 0) {
        // scroll back some or to 0, adjust for any offset from manual scrolling
        const backPixels = currentLeft % itemWidth === 0 ? scrollAmount : currentLeft % itemWidth;
        animateScroll(Math.min(backPixels, currentLeft) * -1);
        trackEvent(`carousel_${trackingLabel}_lft_click`);
      } else {
        // scroll foward some or to end, adjust for any offset from manual scrolling
        const forwardPixels = itemWidth - (currentLeft % itemWidth);
        const max = carousel.scrollWidth - carousel.clientWidth;
        animateScroll(Math.min(forwardPixels, max));
        trackEvent(`carousel_${trackingLabel}_rgt_click`);
      }
    }
  }

  // Hide/show a right/left arrow control
  const toggleArrow = React.useCallback((show, styleClass) => {
    const wrapper = controlsRef.current;
    if (wrapper) {
      wrapper.classList.toggle(styleClass, show);
    }
  }, []);

  // Check controls
  const checkControls = React.useCallback(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      const max = carousel.scrollWidth - carousel.clientWidth;
      // show/hide right arrow
      const isNotMaxScroll = carousel.scrollLeft + 10 < max;
      toggleArrow(isNotMaxScroll, styles.withRightArrow);
      // show/hide left arrow
      const isScrolled = carousel.scrollLeft > 10;
      toggleArrow(isScrolled, styles.withLeftArrow);
    }
  }, [toggleArrow]);

  const scrollRef = React.useRef();

  // Return to left:0 on resize
  const handleWidthResize = React.useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollTo(0,0);
      checkControls();
      // Handle unlikely resize mobile to desktop
      if (!carouselHasScrolled && onCarouselScroll) {
        onCarouselScroll();
      }
    }
  }, [carouselHasScrolled, onCarouselScroll, checkControls]);

  useOnWidthResize(handleWidthResize);

  // Reset scroll to 0 on filter change
  React.useEffect(() => {
    if (carouselRef.current) {
      const carousel = carouselRef.current;
      if (carousel?.scrollLeft && carousel.scrollLeft !== 0) {
        carousel.scrollTo(0,0);
      }
    }
  }, [scrollResetDependency])

  // Monitor category scroll
  React.useEffect(() => {
    const carousel = carouselRef.current;
    const handleCarouselScroll = () => {
      if (!carouselHasScrolled && onCarouselScroll) {
        onCarouselScroll();
      }
      // Show appropriate control arrows after HORIZONTAL scroll
      window.clearTimeout(scrollRef.current);
      scrollRef.current = window.setTimeout(() => {
        checkControls();
      }, 300);
    };

    // Add scroll listeners
    if (carousel) {
      carousel.addEventListener('scroll', handleCarouselScroll);
    }

    // Initialize controls
    checkControls();

    return () => {
      if (carousel) {
        carousel.removeEventListener('scroll', handleCarouselScroll);
      }
    }
  }, [checkControls, carouselHasScrolled, onCarouselScroll]);

  const addlClass = carouselStyle || '';
  const sponsoredClass = isSponsored ? styles.sponsored : '';

  // Determine how many product card images to load on render
  const visibleCardCount = window.innerWidth < 481
    ? 2
    : 5;

  // Not all carousel items are clickable
  const onItemClick = (event, clickFn) => {
    if (clickFn) {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      clickFn();
    }
  }

  return (
    <div className={`${styles.carouselWrap} ${addlClass} ${sponsoredClass}`}>
      <div ref={controlsRef} className={styles.controls}>
        <span className={styles.maskLeft}></span>
        <span className={`${styles.scrollArrow} ${styles.scrollLft}`} onClick={() => scrollItems(-1)}><ChevronLeftRoundedIcon /></span>
        <span className={`${styles.scrollArrow} ${styles.scrollRgt}`} onClick={() => scrollItems(1)}><ChevronRightRoundedIcon /></span>
        <span className={styles.maskRight}></span>
      </div>
      <div ref={carouselRef} className={styles.carousel} style={withStyle}>
        { itemProps?.length
          ? itemProps.map((props, idx) => (
              <div key={`carousel_item_${props.itemKey}`}
                   className={`${styles.carouselItem} ${itemClass || ''}`}
                   data-is-clickable={props.handleClick ? true : false }
                   onClick={(event) => onItemClick(event, props.handleClick)}>
                <CarouselComponent
                  key={`carousel_child_${props.itemKey}`}
                  index={idx}
                  deferImage={idx > visibleCardCount ? !carouselHasScrolled : false}
                  {...props} />
              </div>
            ))
          : null
        }
        { MoreItemsCard
          ? <div className={styles.carouselItem}>
              {MoreItemsCard}
            </div>
          : null
        }
      </div>
    </div>
  );
}

SimpleCarousel.propTypes = {
  CarouselComponent: PropTypes.func.isRequired,
  carouselItemProps: PropTypes.array.isRequired,
  maxItemCount: PropTypes.number,
  trackingLabel: PropTypes.string,
  withStyle: PropTypes.object,
  carouselStyle: PropTypes.string,
  itemClass: PropTypes.string,
  MoreItemsCard: PropTypes.object, // SeeMoreCard component!
  isSponsored: PropTypes.bool,
  onCarouselScroll: PropTypes.func,      // To load offscreen product card images
  carouselHasScrolled: PropTypes.bool,   // To load offscreen product card images
  scrollResetDependency: PropTypes.string
}

export default SimpleCarousel;
