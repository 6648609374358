import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import AISummary from '../ai-generated/AISummary';
import { NOT_A_STRAIN } from '../products/DisplayStrainName';
import PropTypes from 'prop-types';

import styles from './ProductDescription.module.css';

/**
 *  Tweak product descriptions
 *    \n\n => <div class='descBR'></div>
 *    \n => <br />
 *
 *  This results in less whitespace between lines
 */
const formattedDesc = (product) => {
  const { description } = product?.display_info || {};
  return description
    ? description.replace(/\n{2,}/g, "<div class='descBR'></div>").replace(/\n/g, "<br />")
    : 'Product description coming soon!';
}

const AI_ENABLED_CATEGORIES = ['flower', 'pre-rolls'];

/**
 *  Tabbed View of Product Description and AI Strain Summary
 */
const ProductDescription = ({product}) => {
  // Do we have a valid strain value?
  const [enableAISummary, setEnableAISummary] = React.useState();
  // Toggle AI Summary display
  const [showAISummary, setShowAISummary] = React.useState();
  const displayAITab = (enable) => {
    setShowAISummary(enable);
    if (enable) {
      trackEvent('modal_ai_strain_summary_clk');
    }
  }

  const { category, cannabis_type='', strain_name=''} = product.display_info || {};

  // We suppress summaries for invalid data like: strain_name = 'Hybrid'
  React.useEffect(() => {
    if (category && strain_name) {
      setEnableAISummary(
        AI_ENABLED_CATEGORIES.includes(category.name) &&
        !NOT_A_STRAIN.includes(strain_name.toLowerCase())
      );
    }
  }, [category, strain_name])

  return (
    <div className={styles.productDesc}>
      { enableAISummary &&
        <div data-type={cannabis_type} className={styles.tabs}>
          <div className={`${styles.tab} ${showAISummary ? styles.inActiveTab : styles.activeTab}`} onClick={() => displayAITab(false)}>
            Description
            <div className={styles.tabBottom} />
          </div>
          <div className={`${styles.tab} ${showAISummary ? styles.activeTab : styles.inActiveTab}`} onClick={() => displayAITab(true)}>
            AI Strain Summary
            <div className={styles.tabBottom} />
          </div>
        </div>
      }
      { showAISummary
        ? <AISummary product={product} />
        : <div dangerouslySetInnerHTML={{ __html:formattedDesc(product)}}></div>
      }
    </div>
  )
}

ProductDescription.propTypes = {
  product: PropTypes.shape({
    dutchie_sku: PropTypes.string,
    display_info: PropTypes.object,
  }).isRequired,
}

export default ProductDescription;
