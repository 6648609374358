import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import ProductCarousel, { MAX_CAROUSEL_PRODUCTS } from './ProductCarousel';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';

import styles from './ProductCarousel.module.css';

export const thcValue = (product) => {
  const { lab_results=[] } = product.display_info || {};
  const thcTest = lab_results.find(result => result.labTest === 'THC');
  return thcTest?.value || 0;
}

const HeaderWithSort = ({carouselTitle, sorted, sortedLabel, unSortedLabel, sortFn}) => (
  <div className={styles.headerGrid}>
    <span>{carouselTitle}</span>
    <span className={styles.headerLink} onClick={() => sortFn(!sorted)}>
      { sorted ? sortedLabel : unSortedLabel }
    </span>
  </div>
)

const ProductsByThcCarousel = ({
  categoryName,
}) => {

  const { productsByCategory } = React.useContext(ProductPreviewContext);

  const [categoryProducts, setCategoryProducts] = React.useState();
  const [sortedProducts, setSortedProducts] = React.useState();
  const [sortHigh, setSortHigh] = React.useState(true);

  // Sort products by THC%
  React.useEffect(() => {
    const products = productsByCategory.get(categoryName);
    if (products?.length) {
      // Don't mutate the provider order
      const productsClone = [ ...products].sort(
        (a,b) => sortHigh
          ? thcValue(b) - thcValue(a)
          : thcValue(a) - thcValue(b))
      setSortedProducts(productsClone.slice(0,MAX_CAROUSEL_PRODUCTS));
      setCategoryProducts(products);
    }
  }, [productsByCategory, categoryName, sortHigh])

  return ( sortedProducts
    ? sortedProducts.length === 0
      ? null
      : <ProductCarousel
          carouselTitle={`${categoryName} by THC%`}
          carouselTitleComponent={(
            <HeaderWithSort
              carouselTitle='Products by THC%'
              sorted={sortHigh}
              sortedLabel='Sort Lowest First'
              unSortedLabel='Sort Highest First'
              sortFn={setSortHigh} />
          )}
          categoryName={categoryName}
          categoryCount={categoryProducts.length}
          carouselProducts={sortedProducts}
          withMoreCard
          trackingPrefix='by_thc'
          seeAllTitle={categoryName}
          seeAllClick={() => {
            navigateTop(`/products/Flower`);
          }} />
    : <Spinner />
  )
}

ProductsByThcCarousel.propTypes = {
  categoryName: PropTypes.string.isRequired
}

export default ProductsByThcCarousel;
