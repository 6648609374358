import * as React from 'react';

import Header, { HeaderSize } from '../styleguide/Header';
import CarouselHeaderWithTypeFilter from './CarouselHeaderWithTypeFilter';
import { CarouselStyle } from '../common/SimpleCarousel';
import PropTypes from 'prop-types';

import styles from './ProductCarousel.module.css';

/**
 * Display carousel title: text or component
 */
const ProductCarouselHeader = ({
  carouselTitle,
  carouselTitleComponent,
  carouselSubTitle,
  categoryName,
  withTypeFilter,
  cannabisType,
  setCannabisType,
  carouselStyle=CarouselStyle.PRODUCT_CARDS,
}) => {

  return ( carouselTitle
    ? <Header size={HeaderSize.Medium_Static} withStyle={{ margin:0 }}>
        { withTypeFilter && categoryName
          ? <CarouselHeaderWithTypeFilter
              carouselTitle={carouselTitle}
              category={categoryName}
              type={cannabisType}
              setType={setCannabisType} />
          : carouselTitleComponent
            ? carouselTitleComponent
            : [CarouselStyle.SUGGESTED_PRODUCTS,
               CarouselStyle.CART_SUGGESTED_PRODUCTS].includes(carouselStyle)
              ? <span className={styles.suggestedHeader}>
                  {carouselTitle}
                </span>
              : <span className={styles.categoryName}>
                  {carouselTitle}
                </span>
        }
        { carouselSubTitle &&
          <div className={styles.subTitle}>
            {carouselSubTitle}
          </div>
        }
      </Header>
    : null
  );
}

ProductCarouselHeader.propTypes = {
  carouselTitle: PropTypes.string.isRequired,
  carouselTitleComponent: PropTypes.object,
  carouselSubTitle: PropTypes.string,
  categoryName: PropTypes.string,
  withTypeFilter: PropTypes.bool,
  cannabisType: PropTypes.string,
  setCannabisType: PropTypes.func,
  carouselStyle: PropTypes.string,
};

export default ProductCarouselHeader;
