import * as React from 'react';

import { ModalContext, ModalType } from '../modal/ModalProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { categoryTaglines } from './carouselCategoryText';
import { trackingString, partnerLabel } from '../analytics/tracking';
import { useAnalyticsStore } from '../../App';
import DisplayCurrentSlotETACompact from '../timeSlots/DisplayCurrentSlotETACompact';
import DisplayNextDiscountedSlot from '../timeSlots/DisplayNextDiscountedSlot';
import SeeAllButton from '../styleguide/DetourButton';
import SeeMoreCard from './carousel/SeeMoreCard';
import AddedToCartCheck from '../products/AddedToCartCheck';
import ProductPreviewCard from '../products/ProductPreviewCard';
import SimpleCarousel, { CarouselStyle } from '../common/SimpleCarousel';
import ProductCarouselHeader from './ProductCarouselHeader';
import useCategoryProductsByType, { ALL_TYPES } from './useCategoryProductsByType';
import PropTypes from 'prop-types';

import styles from './ProductCarousel.module.css';

export const MAX_CAROUSEL_PRODUCTS = 25;

/**
 * This component will display a carousel of category-specific products for a dispensary
 */
const ProductCarousel = ({
  carouselTitle,
  carouselTitleComponent,
  carouselSubTitle,
  jumpLinkId, // For down-page navigation/linking
  categoryName,
  categoryCount=0, // superset count!, not the carousel item count
  carouselProducts=[],
  allRelatedProducts,  // used for suggested products: modal to modal display
  carouselStyle=CarouselStyle.PRODUCT_CARDS,
  seeAllTitle,
  seeAllClick,
  withTypeFilter,
  withMoreCard,
  trackingPrefix,
  isSuggestedProduct,
  isSponsored,
  isReOrder,
}) => {

  const { trackEvent } = useAnalyticsStore();
  const { addItem } = React.useContext(CartItemsContext);
  const { displayModal } = React.useContext(ModalContext);
  const [showAddedCheck, setShowAddedCheck] = React.useState();
  const [moreItemsCount, setMoreItemsCount] = React.useState();

  // For (shop_mdl_)p12345_edibl(_item_clk) etc. (max: 30 CHAR!)
  const contextTrackingLabel = React.useCallback((product) => {
    let context;
    // TODO: Migrate all carousels to this
    if (trackingPrefix) {
      context = `${trackingPrefix}_`;
    } else {
      context = isReOrder ? 'reord_' : partnerLabel(product, isSponsored);
    }
    const { name:catName } = product.display_info.category;
    const categoryLabel = trackingString(catName).substring(0,5);
    return `${context}${categoryLabel}`;
  }, [trackingPrefix, isReOrder, isSponsored]);

  // For (shop_)csl_p12345_edibl_item_clk etc. (which is the max: 30 CHAR!)
  const carouselTrackingLabel = React.useCallback((product, action) => {
    const contextLabel = contextTrackingLabel(product);
    return `csl_${contextLabel}_${action}`;
  }, [contextTrackingLabel]);

  // This is the Quick Add carting only
  const handleAddToCart = React.useCallback((productId, productObj, quantity) => {
    trackEvent(carouselTrackingLabel(productObj, 'cart_clk'));
    setShowAddedCheck(true);
    addItem(productId, productObj, quantity);
  }, [trackEvent, carouselTrackingLabel, addItem]);

  const [itemProps, setItemProps] = React.useState();
  // Cannabis type for optional carousel filtering
  const [cannabisType, setCannabisType] = React.useState(ALL_TYPES);
  const filteredCarouselProducts = useCategoryProductsByType(carouselProducts, cannabisType);

  React.useEffect(() => {
    const getPropArray = (products) => {
      const propArray = [];
      products.forEach(product => {
        propArray.push({
          itemKey: product.id,
          Id: product.id,
          product: product,
          handleClick: () => {
              trackEvent(carouselTrackingLabel(product, 'item_clk'));
              const modalType = carouselStyle === CarouselStyle.SUGGESTED_PRODUCTS
                ? ModalType.SUGGESTED_PRODUCT
                : ModalType.PRODUCT;
              displayModal(modalType, {
                itemId: product.id,
                title: product.display_info.name,
                categoryName: product.display_info.category.display_name,
                trackingLabel: contextTrackingLabel(product),  // display/click handled later
                relatedProductsCarouselTitle: carouselTitle,  // Product Modal to Product Modal
                relatedProducts: allRelatedProducts || filteredCarouselProducts,  // allRelatedProducts is also for Modal to Modal
              });
          },
          handleCartClick: (quantity) => {
            handleAddToCart(product.id, product, quantity);
            // Quick add card button
            trackEvent(carouselTrackingLabel(product, 'add_clk'));
          },
          isSuggestedProduct
        });
      });
      return propArray;
    }
    setItemProps(getPropArray(
      filteredCarouselProducts.slice(0,MAX_CAROUSEL_PRODUCTS)
    ));
    // Only show the MoreItemsCard in the unfiltered state
    setMoreItemsCount(
      cannabisType === ALL_TYPES
      ? Math.max(categoryCount, carouselProducts.length) - MAX_CAROUSEL_PRODUCTS
      : 0
    );
  }, [trackEvent, carouselTrackingLabel, contextTrackingLabel,
      carouselStyle, handleAddToCart, displayModal,
      carouselTitle, carouselProducts, filteredCarouselProducts, cannabisType,
      allRelatedProducts, categoryCount, isSuggestedProduct, isSponsored]);

  const [tagline, setTagline ] = React.useState();
  const [seeAllLabel, setSeeAllLabel] = React.useState();

  // Set See All text and tag line
  React.useEffect(() => {
    setSeeAllLabel(`${seeAllTitle || carouselTitle} (${categoryCount})`);
    if (categoryTaglines[carouselTitle]) {
      setTagline(categoryTaglines[carouselTitle]);
    }
  },[categoryCount, carouselTitle, seeAllTitle])

  // Used to defer product image load for offscreen product cards
  const [carouselHasScrolled, setCarouselHasScrolled] = React.useState();

  const handleSeeAllClick = React.useCallback((trackingLabel) => {
    if (seeAllClick) {
      seeAllClick();
      trackEvent(trackingLabel);
    }
  }, [seeAllClick, trackEvent]);

  return ( itemProps?.length
    ? <>
        <div className={styles.sectionHeader} id={jumpLinkId}>
          <div className={styles.headerAndTagline}>
            <ProductCarouselHeader
              carouselTitle={carouselTitle}
              carouselTitleComponent={carouselTitleComponent}
              carouselSubTitle={carouselSubTitle}
              categoryName={categoryName}
              carouselStyle={carouselStyle}
              withTypeFilter={withTypeFilter}
              cannabisType={cannabisType}
              setCannabisType={setCannabisType} />
            { carouselStyle === CarouselStyle.PRODUCT_CARDS &&
              <div className={styles.slotCallouts}>
                <DisplayCurrentSlotETACompact />
                <DisplayNextDiscountedSlot />
              </div>
            }
            { tagline &&
              <div className={styles.sectionTagline}>{tagline}</div>
            }
          </div>
          {/* No see all link on suggested carsousel */}
          { seeAllClick &&
            <div className={styles.seeAllLink} onClick={() => handleSeeAllClick('see_more_lnk_clk')}>
              View All <span className={styles.viewAllDetails}>{seeAllLabel}</span>
            </div>
          }
        </div>

        <SimpleCarousel
          CarouselComponent={ProductPreviewCard}
          carouselItemProps={itemProps}
          maxItemCount={MAX_CAROUSEL_PRODUCTS}
          trackingLabel="home_products"
          carouselStyle={carouselStyle}
          MoreItemsCard={
            withMoreCard && moreItemsCount > 0
              ? <SeeMoreCard
                  moreLabel={seeAllTitle || carouselTitle}
                  moreCount={moreItemsCount}
                  totalCount={categoryCount}
                  onClick={seeAllClick} />
              : null
          }
          isSponsored={isSponsored}
          onCarouselScroll={() => setCarouselHasScrolled(true)}
          carouselHasScrolled={carouselHasScrolled}
          scrollResetDependency={cannabisType} />
        { seeAllClick &&
          <div className={styles.seeAllButtonWrap}>
            <SeeAllButton
              text={`View All ${seeAllLabel}`}
              isCentered
              withStyle={{width:'calc(100% - 2rem)', margin:'0 1rem', padding:'.5rem .75rem'}}
              handleClick={() => handleSeeAllClick('see_more_btn_clk')} />
          </div>
        }

        { showAddedCheck &&
          <AddedToCartCheck
            key={`added_chk_${carouselTitle}`}
            closeFn={() => {
              setShowAddedCheck(false);
            }}
            closeMs={2000}
          />
        }
      </>
    : null
  );
}

ProductCarousel.propTypes = {
  carouselTitle: PropTypes.string.isRequired,
  carouselTitleComponent: PropTypes.object,
  carouselSubTitle: PropTypes.string,
  jumpLinkId: PropTypes.string,
  categoryName: PropTypes.string,
  categoryCount: PropTypes.number.isRequired,
  carouselProducts: PropTypes.array.isRequired,
  allRelatedProducts: PropTypes.array,
  carouselStyle: PropTypes.string,
  seeAllTitle: PropTypes.string,
  seeAllClick: PropTypes.func,
  withTypeFilter: PropTypes.bool,
  withMoreCard: PropTypes.bool,
  trackingPrefix: PropTypes.string,
  isSuggestedProduct: PropTypes.bool,
  isSponsored: PropTypes.bool,
  isReOrder: PropTypes.bool,
};

export default ProductCarousel;

/**
 * Sample usages:
 *
 * ../products/SuggestedProductsCarousel.jsx
 * ../home/PreviewProducts.jsx
 * ../home/SubCategoryCarousels.jsx
 * ../reorder/ReOrderCarousel.jsx
 * ../brand/BrandCarousels.jsx
 * ../deals/SaleItemCarousels.jsx
 * ../suggestedProducts/CartSuggestedProductsCarousel
 *
 */
