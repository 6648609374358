import * as React from 'react';

import { trackEvent, trackingString } from '../analytics/tracking';
import { DISPLAY_NEW_ITEMS_MAX } from './useNewlyAddedProducts';
import { navigateTop } from '../routing/router-utils';
import { navigate } from '@reach/router';
import Header, {HeaderSize} from '../styleguide/Header';
import styles from './NewProducts.module.css';

/**
 *  Home page links to the "New & Restocked" carousels
 */
const NewlyAddedLinks = ({
  newlyAddedSinceDate,
  newlyAddedByCategory
}) => {

  const categoryClick = React.useCallback((categoryName) => {
    // For excessive new products, link to category page
    if (newlyAddedByCategory.get(categoryName).length > DISPLAY_NEW_ITEMS_MAX) {
      navigateTop(`/popular/${categoryName}`);
      trackEvent(`new_itms_${trackingString(categoryName)}_many_clk`);
    } else {
      navigate(`/popular/${categoryName}#newly_added`).then(() => {
        const elem = document.getElementById('newly_added');
        if (elem) {
          window.setTimeout(
            () => elem.scrollIntoView()
          , 300);
        }
      });
      trackEvent(`new_itms_${trackingString(categoryName)}_link_clk`);
    }
  }, [newlyAddedByCategory])

  return ( newlyAddedByCategory?.size
    ? <div className={styles.newProductsWrapper}>
        <Header size={HeaderSize.Medium_Static}>
          <img alt="" className={styles.newProductImage} src="/img/new_products_blue.jpg" height="38" />
          New & Restocked!
          <div className={styles.linksSubHeader}>
            Fresh drops since your last visit on
            <span className={styles.newSinceDate}>{newlyAddedSinceDate}</span>
          </div>
        </Header>
        <div className={styles.linkGrid}>
          {[...newlyAddedByCategory.keys()].map((key) => (
            <div key={key} className={styles.linkGridItem}
                 onClick={() => categoryClick(key)}>
              {key}{' '}
              ({
                newlyAddedByCategory.get(key).length > DISPLAY_NEW_ITEMS_MAX
                  ? `${DISPLAY_NEW_ITEMS_MAX}+`
                  : newlyAddedByCategory.get(key).length
              })
            </div>
          ))}
        </div>
      </div>
    : null
  )
}

export default NewlyAddedLinks;
