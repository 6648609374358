import React from 'react';

import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { devLog } from '../util/util';
import { PartnerContext } from '../brand/PartnerProvider';
import { useAnalyticsStore } from '../../App';
import useAddlContextTracking, { TRACK_CONTEXT } from '../analytics/useAddlContextTracking';
import useIntercomLogging, { INTERCOM_SEGMENT_KEYS } from '../intercom/useIntercomLogging';
import { RegistrationContext } from './RegistrationProvider';
import { regFlowSuccessKey, storeValue, removeSessionVal, subscribeEmailKey } from '../util/storage-utils';
import { newCustomerEndpoint } from '../../constants/api-endpoints';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';

function ProcessRegistrationInline({
  onSuccess,
  onFailure
}) {

  const {
    name, email, password, location, // { street_address, city, state, zip_code }
    phoneNumber: tel_number, apartmentNumber: apartment_number, birthDate, // { day, month, year }
    primaryPhotoId, referrerId, acceptMarketing: marketing_accepted
  } = React.useContext(RegistrationContext);

  const { trackTestEvent, trackError } = useAnalyticsStore();
  const { trackEventWithContext } = useAddlContextTracking();
  const { partnerReferralVendorId, partnerReferralDomain } = React.useContext(PartnerContext);
  const { logIntercomValue } = useIntercomLogging();

  // Registration submitted, Success or Failure
  const [submitted, setSubmitted] = React.useState();

  // Post registration signin: Not handling failure currently when registration is a success.
  const signInNewUser = React.useCallback((email, password, callback) => {
    signInWithEmailAndPassword(auth, email, password).catch(error => {
      const { errorCode, errorMessage } = error;
      trackError(`reg_flow_signin_error_after_signup_${errorCode}`);
      // TODO: Pass error to callback for display on registration success page
      // `Error signing in with password and email! ${errorMessage}`
      devLog(`Signin error after signup: ${errorMessage}`);
    }).then(() => {
      // continue to Registration Complete
      callback && callback();
    }).catch((error) => {
      const { errorCode, errorMessage } = error;
      devLog(`sign in error: ${errorMessage}`);
      trackError(`reg_flow_signin_cb_error_${errorCode}`);
    });
  }, [trackError]);

  // Collect all the info required for registration
  const buildCustomerInfo = React.useCallback(() => {
    const dobInfo = birthDate.split("/");
    const dob = {
      month: dobInfo[0],
      day: dobInfo[1],
      year: dobInfo[2]
    };

    const {
      street_address, city, state, zip_code, geo_coordinates,
    } = location;

    const info = {
      name,
      email,
      password,
      location: {
        street_address,
        city,
        state,
        zip_code,
        apartment_number,
        geo_coordinates, // latitude, longitude
      },
      dob,
      tel_number,
      marketing_email_opted_in: marketing_accepted,
      marketing_sms_text_opted_in: marketing_accepted,
      referrer_vendor_id: partnerReferralVendorId,
      // TODO add referrer_domain (same as orders)
    };

    // Berbix currently disabled, add ID image if available (secondary not needed)
    if (primaryPhotoId) {
      info.photo_ids = {
        primary: primaryPhotoId
      };
    }

    // Refer Credit: Add referrer User Id if applicable
    if (referrerId) {
      info.referrer_user_id = referrerId;
    }

    // Reg will fail if apartment_number is empty string
    if (info.location['apartment_number'] === undefined ||
      /\S+/.test(info.location['apartment_number']) === false) {
      delete info.location['apartment_number'];
    }
    return info;
  }, [name, email, password, location, apartment_number, birthDate, tel_number, primaryPhotoId, partnerReferralVendorId, referrerId, marketing_accepted]);

  const handleError = React.useCallback((error) => {
    if (onFailure) {
      onFailure(error); /* will re-enable submit */
    }
    setSubmitted(false);
  }, [onFailure]);

  const controllerRef = React.useRef(new AbortController());

  React.useEffect(() => {
    const controller = controllerRef.current;
    try {
      if (!submitted) {
        fetch(newCustomerEndpoint, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(buildCustomerInfo()),
          signal: controller.signal
        }).then(response => response.json())
          .then(data => {
            setSubmitted(true);
            // check for errors
            if (data["error"]) {
              trackError('registration_failure');
              handleError(data["error"]);
            } else {
              try {
                trackEventWithContext('registration_success', [TRACK_CONTEXT.DEVICE, TRACK_CONTEXT.PARTNER]);
                trackTestEvent('registration_success');
                // Determines SignIn versus SignUp at checkout
                // Uses timestamp for "new user" scoping
                storeValue(regFlowSuccessKey, new Date().getTime());
                // Clear stored email from Klaviyo subscribe form
                removeSessionVal(subscribeEmailKey);
              } catch(e) {
                trackError('registration_success_tracking_error');
              }
              signInNewUser(email, password, () => {
                // Log partner referrer if available
                if (partnerReferralDomain) {
                  logIntercomValue(INTERCOM_SEGMENT_KEYS.SIGNUP_REFERRER, partnerReferralDomain);
                }
                onSuccess();
              });
            }
          })
          .catch(error => {
            handleError(error);
          });
      }
    } catch (e) {
      handleError(`registration failed ${e.message}`);
    }
    // TODO: Prevent dupe orders?
    //return () => controller.abort();
  }, [email, password, buildCustomerInfo, trackEventWithContext,
      trackTestEvent, trackError, submitted, signInNewUser, onSuccess,
      logIntercomValue, partnerReferralDomain, handleError]);

  return (
    <Spinner />
  );
}

ProcessRegistrationInline.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired
}

export default ProcessRegistrationInline;
