import * as React from 'react';

import config from '../../config';
import { trackEvent } from '../analytics/tracking';
import {
  storeValue, getStoredValue, setSessionVal,
  subscribeFormShownKey, subscribeEmailKey,
  regFlowSuccessKey, userIs21Key
} from '../util/storage-utils';
import { injectScript } from '../util/util';
import { UserContext } from '../providers/UserProvider';
import { AttributionContext } from '../providers/AttributionProvider';
import MuiModal from '../modal/MuiModal';
import Spinner from '../common/Spinner';

import styles from './Klaviyo.module.css';

const KLAVIYO_SCRIPT_SRC = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=TzSVDb`;
const KLAVIYO_FORM_ID = 'TgrqSU';
const TRACKING_LABEL = 'klaviyo_subscribe';
const SHOWN_ATTRIBUTE = 'data-subscribe-shown';

// This needs to match what we have set up in the Klaviyo form
const SUBSCRIBE_PROMO_CODE = 'SUBSCRIBEZYP';

// Prompt unknown users for email for use in marketing
const KlaviyoSubscribeModal = () => {

  const { user } = React.useContext(UserContext);
  const { setSiteLinkDiscountCode } = React.useContext(AttributionContext);

  // Apply biz logic for who gets subscribe popup
  const [showModal, setShowModal] = React.useState();
  // Monitor Klaviyo events
  const [formLoading, setFormLoading] = React.useState(true);
  const [formSubmitted, setFormSubmitted] = React.useState();
  // Observe email entry for use in reg flow
  const [inputVal, setInputVal] = React.useState();

  /**
   * We need to wait for Intercom to load
   * Then make sure there's no promo banner
   */
  const intercomBannerExists = () => {
    return !window.Intercom.booted ||
      document.querySelector('.intercom-app iframe[title="Intercom live chat banner"]');
  }

  const passesStorageCheck = () => {
    return getStoredValue(userIs21Key) &&          // Age 21 modal shown/closed
           !getStoredValue(regFlowSuccessKey) &&   // User has not already registered
           !getStoredValue(subscribeFormShownKey); // Subscribe NOT already shown
  }

  React.useEffect(() => {
    if (user?.isAnonymous &&
        !document.body.getAttribute(SHOWN_ATTRIBUTE) &&
        passesStorageCheck() &&
        !intercomBannerExists()
    ) {
      setShowModal(true);
      injectScript(KLAVIYO_SCRIPT_SRC);
    } else {
      setShowModal(false);
      if (config.IS_DEV) {
        console.log(`SUBSCRIBE Anonymous user: ${user?.isAnonymous}`);
        console.log(`SUBSCRIBE Body attribute: ${document.body.hasAttribute(SHOWN_ATTRIBUTE)}`);
        console.log(`SUBSCRIBE Passed storage check: ${passesStorageCheck()}`);
        console.log(`SUBSCRIBE Intercom booted: ${!!window.Intercom.booted}`);
        console.log(`SUBSCRIBE Intercom banner: ${!!document.querySelector('.intercom-app iframe[title="Intercom live chat banner"]')}`);
      }
    }
  }, [user]);

  React.useEffect(() => {
    const handleKlaviyoMessage = (event) => {
      if (event.detail.type === 'embedOpen') {
        setFormLoading(false);
        document.body.setAttribute(SHOWN_ATTRIBUTE, true);
        trackEvent(`${TRACKING_LABEL}_display`);
        storeValue(subscribeFormShownKey, true);
      }
      if (event.detail.type === 'submit') {
        setFormSubmitted(true);
        trackEvent(`${TRACKING_LABEL}_submit`);
        setSessionVal(subscribeEmailKey, inputVal);
      }
      if (event.detail.type === 'viewedStep') {
        // If we've submitted the form, then we're at the success/thank you step
        if (formSubmitted) {
          // Apply related discount code
          setSiteLinkDiscountCode(SUBSCRIBE_PROMO_CODE);
          // Style success content
          if (formWrapRef.current) {
            formWrapRef.current.classList.add(styles.formSubmitted);
          }
          trackEvent(`${TRACKING_LABEL}_success`);
        }
      }
    }
    window.addEventListener("klaviyoForms", handleKlaviyoMessage);
    return () => window.removeEventListener("klaviyoForms", handleKlaviyoMessage);
  }, [inputVal, formSubmitted, setSiteLinkDiscountCode]);

  /* Store email for reg flow */
  const onKeyUp = (event) => {
    const { target } = event;
    if (target?.nodeName.toLowerCase() === 'input' &&
        target.id?.indexOf('email_') === 0) {
      setInputVal(target.value);
    }
  }

  const handleClose = React.useCallback(() => {
    setShowModal(false);
    if (!formSubmitted) {
      trackEvent(`${TRACKING_LABEL}_cancel`);
    }
  }, [formSubmitted]);

  const formWrapRef = React.useRef();

  return ( showModal
    ? <MuiModal className={styles.subscribeModal} open={showModal} closeFn={handleClose}>
        <div ref={formWrapRef} className={styles.formWrap} onKeyUp={onKeyUp}>
          <img alt="" className={styles.checkmark} src="/img/white_check_squared.png" />
          {/* Klaviyo injects form here */}
          <div className={`klaviyo-form-${KLAVIYO_FORM_ID}`}></div>
          { formLoading &&
            <div className={styles.spinner}><Spinner size={64} /></div>
          }
          <div className={styles.closeWrap}>
            <span className={styles.closeText} onClick={handleClose}>
              { formSubmitted
                  ? 'Close'
                  : 'No Thanks'
              }
            </span>
          </div>
        </div>
      </MuiModal>
    : null
  )
}

export default KlaviyoSubscribeModal;
