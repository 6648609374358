import { useEffect, useState } from "react";
import { thcValue } from "./ProductsByThcCarousel";
import { getSortPrice } from "../products/DisplayPrice";

export const ALL_TYPES = 'All Types';
export const HIGH_THC = 'High THC';
export const LOW_PRICE = 'Low Price';

const useCategoryProductsByType = (products, type) => {

  const [filteredProducts, setFilteredProducts] = useState([]);

  // Filter products by selected type
  useEffect(() => {
    // Fast fail
    if ( type === ALL_TYPES) {
      setFilteredProducts(products);
    } else {
      if (products?.length) {
        if (type === HIGH_THC) {
          setFilteredProducts([ ...products].sort((a,b) => thcValue(b) - thcValue(a)));
        } else if (type === LOW_PRICE) {
          setFilteredProducts([ ...products].sort((a,b) => getSortPrice(a) - getSortPrice(b)));
        } else {
          const filtered = products.filter(product =>
            product.display_info.cannabis_type
              ? product.display_info.cannabis_type === type
              : false
          )
          setFilteredProducts(filtered);
        }
      }
    }
  }, [products, type])

  return filteredProducts;
}

export default useCategoryProductsByType;
