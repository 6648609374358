import * as React from "react";

import ToolTip from "../common/ToolTip";
import { STACKING } from "../layout/stackingConstants";

const AISummaryTooltip = ({
  inModal,
}) => {

  const wrapperStyle = {
    display: 'inline-block',
    whiteSpace: 'nowrap',
  };

  const btnStyle = {
    verticalAlign: -5,
    zIndex: inModal ? STACKING.MODAL_TOOLTIP : 1,
    padding: 0,
    marginLeft: -4,
  };

  return (
    <div style={wrapperStyle}>
      <ToolTip
        tipTitle="AI-Generated Content"
        placement="bottom-end"
        inModal={inModal}
        enableFlip={true}
        buttonTextBefore="About AI-generated Strain Summaries"
        buttonStyle={btnStyle}
        toolTipStyle={{zIndex:inModal ? STACKING.MODAL_TOOLTIP : 1}}>
          We're using AI to collect information about specific
          cannabis strains to give you a better sense for the products
          we sell. We can't guarantee that this content is 100% accurate,
          but it's helpful and saves time when compared to
          googling additional product details yourself!
      </ToolTip>
    </div>
  );
};

export default AISummaryTooltip;
