import { useContext, useReducer } from "react";
import { ProductPreviewContext } from "../products/ProductPreviewProvider";

import {
  WHOLE_FLOWER_FILTER_NAME,
  SMALL_BUDS_FILTER_NAME,
  PRE_GROUND_FILTER_NAME,
  FLOWER_TYPE_FILTERS,
  isSmallBuds,
  isPreGround,
} from '../products/FilteredProductsProvider';

const updateSubCategories = (categoryProducts) => {
  // Map of subCategory name to full object ( display_name etc.)
  const subCategoryMap = categoryProducts.reduce((nameToObjMap, product) => {
    const name = product.display_info.sub_category?.name;
    if (name) {
      nameToObjMap.set(name, product.display_info.sub_category);
    }
    return nameToObjMap;
  }, new Map());
  // Sort alphabetically
  const subCatArray =  Array.from(subCategoryMap.values());
  if (subCatArray.length) {
    subCatArray.sort((a, b) => a.display_name.localeCompare(b.display_name));
  }
  return subCatArray;
};

// Build the unique  brands/types/etc. for a subCategory
const buildFilterItems = (categoryProducts, productDetail) => {
  // build the filter set
  const detailSet = categoryProducts.reduce((unique, product) => {
    const productNode = productDetail === 'grams'
      ? product
      : product.display_info;
    if (productNode[productDetail]) {
      unique.add(productNode[productDetail]);
    }
    return unique;
  }, new Set());
  const itemArray = Array.from(detailSet);
  if (typeof itemArray[0] === 'number') {
    return itemArray.sort((a,b) => a - b);
  } else {
    return itemArray.sort();
  }
};

// Build the Flower Type filter set
const buildFlowerFilterItems = (categoryProducts) => {
  const detailSet = new Set([WHOLE_FLOWER_FILTER_NAME]);
  // Return false/Break if we've found all possible types
  categoryProducts.every(product => {
    if (isPreGround(product)) {
      detailSet.add(PRE_GROUND_FILTER_NAME);
    } else if (isSmallBuds(product)) {
      detailSet.add(SMALL_BUDS_FILTER_NAME);
    }
    return detailSet.size < FLOWER_TYPE_FILTERS.length;
  })
  return Array.from(detailSet).sort();
};

// Check for Sale Price items in SubCategory
const hasSaleItems = (categoryProducts) => {
  return categoryProducts.filter(product => product.discount_data).length > 0;
};

const buildMoodItems = (categoryProducts) => {
  // build the filter set
  const detailSet = categoryProducts.reduce((unique, product) => {
    const { moods=[] } = product.display_info;
    if (moods?.length) {
      moods.forEach(mood => {
        unique.add(mood);
      })
    }
    return unique;
  }, new Set());
  const itemArray = Array.from(detailSet);
  return itemArray.sort();
};

// Check for THC items in SubCategory
const hasTHCItems = (categoryProducts) => {
  const thcProducts = categoryProducts.filter(product =>
    product.display_info.lab_results?.length &&
    product.display_info.lab_results?.filter(test => test.labTest['THC']?.value > 0)
  );
  return thcProducts.length > 0;
}

/**
 * The state of the applicable filters for a given category
 */
const useCategoryFiltersReducer = () => {

  const { bundleInfoByCategory } = useContext(ProductPreviewContext);

  const initialState = {
    // subCategories are objects!
    subCategories: [],
    subCategoryWeights: [],
    subCategoryBrands: [],
    subCategoryTypes: [],
    subCategoryFlowerTypes: [],
    subCategoryBundles: [],
    subCategoryHasSaleItems: false,
    subCategoryMoods: [],
    subCategoryHasTHCItems: false,
  }

  const productFilterReducer = (state, action) => {
    switch(action.type) {
      case 'updateCategory':
        const categoryProducts = action.productMap.get(action.categoryName) || [];
        return {
          subCategories: updateSubCategories(categoryProducts),
          subCategoryWeights: buildFilterItems(categoryProducts, 'grams'),
          subCategoryBrands: buildFilterItems(categoryProducts, 'brand'),
          subCategoryTypes:  buildFilterItems(categoryProducts, 'cannabis_type'),
          subCategoryFlowerTypes: buildFlowerFilterItems(categoryProducts),
          subCategoryBundles: bundleInfoByCategory?.get(action.categoryName),
          subCategoryHasSaleItems: hasSaleItems(categoryProducts),
          subCategoryMoods: buildMoodItems(categoryProducts),
          subCategoryHasTHCItems: hasTHCItems(categoryProducts),
        };
      default:
        return state;
    }
  }

  const [filterState={}, dispatch] = useReducer(productFilterReducer, initialState);

  return [filterState, dispatch];
}

export default useCategoryFiltersReducer;
