import { devLog } from '../util/util';

// Optimize when the category spend is > 0
export const shouldOptimizeIndex = (index, categoriesBySpend) => {
  if (index < categoriesBySpend.length) {
    return categoriesBySpend[index].totalSpend > 0;
  }
  return false;
}

const moveToIndex = (index, categoryName, categories) => {
  const catIndex = categories.findIndex(cat => cat[0] === categoryName);
  if (catIndex >= 0 && catIndex < categories.length && catIndex !== index) {
    const [catToMove] = categories.splice(catIndex, 1);
    categories.splice(index, 0, catToMove);
    devLog(`move ${categoryName} to ${index}`);
  }
}

// Optimize categories based on customer spend
export const optimizeCategoryKeys = (productsByCategory, categoriesBySpend) => {
  if (productsByCategory && categoriesBySpend) {
    const displayCategoryArray = Array.from(productsByCategory);
    let index = 0;
    // While spend > 0
    while(shouldOptimizeIndex(index, categoriesBySpend)) {
      moveToIndex(index, categoriesBySpend[index].display_name, displayCategoryArray);
      index += 1;
    }
    return new Map(displayCategoryArray);
  }
  // unchanged standard order
  return productsByCategory;
}
