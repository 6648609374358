import * as React from 'react';

import { devLog } from '../util/util';
import { groupByStandardCategories } from '../products/product-utils';
import { currentSessionProductSkusKey, priorSessionProductSkusKey,
         storeSessionValueStringified, getStoredSessionValueJson,
         storeValueStringified, getStoredValueJson } from '../util/storage-utils';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';

// 'Dec 16, 2024'
const DATE_FORMAT = { year:'numeric', month:'short', day:'numeric' };
const dateString = (date) => date.toLocaleDateString('en-US', DATE_FORMAT);

export const DISPLAY_NEW_ITEMS_MAX = 25;

/**
 * Store the current session Skus for a future session
 * in local/persistent storage.
 */
const updateStoredSkuData = (sessionDate, sessionSkus) => {
  storeValueStringified(
    priorSessionProductSkusKey, {sessionDate, sessionSkus}
  );
}

const mapByCategory = (allProducts, priorSessionSkus) => {
  if (allProducts?.length && priorSessionSkus?.length) {
    const newProducts = allProducts.filter(product =>
      !priorSessionSkus.includes(product.dutchie_sku) &&
      !(product.display_sold_out_status && product.quantity_available === 1) // Suppress sold out
    );
    const { productsByCategory } = groupByStandardCategories(newProducts);
    return productsByCategory;
  }
  return undefined;
}

/**
 * This hook hanldes the following tasks:
 * 1) Read the newly added skus from SESSION STORAGE (for session persistence)
 * 2) OR determine new newly added skus from persistent LOCAL STORAGE and set a new SESSION value
 * 3) Store the current product skus in LOCAL STORAGE for future use in #2
 *
 * TODO: Exclude "Sold Out/Quantity=1" products
 */
const useNewlyAddedProducts = () => {

  // You can't fully deconstruct sessionProductSkus early in the page load
  const { allProducts, sessionProductSkus } = React.useContext(ProductPreviewContext);

  const [newlyAddedByCategory, setNewlyAddedByCategory] = React.useState();
  const [newlyAddedSinceDate, setNewlyAddedSinceDate] = React.useState();

  React.useEffect(() => {
    if (allProducts?.length && sessionProductSkus) {
      let newItemsByCategory, newSinceDate;
      // Stored data: { sessionDate, sessionSkus }
      const priorSessionData = getStoredValueJson(priorSessionProductSkusKey);
      const currentSessionDate = dateString(new Date());

      // Check current SESSION data first
      // We want the Newly Added carousel data to survive a same-day page reload
      const currentSessionData = getStoredSessionValueJson(currentSessionProductSkusKey)
      const { sessionStorageDate, priorSessionDate, priorSessionSkus } = currentSessionData || {}
      // If the session data is dated TODAY (and data is NOT today's skus), use it
      if (sessionStorageDate === currentSessionDate &&
          priorSessionDate !== currentSessionDate
      ) {
        newItemsByCategory = mapByCategory(allProducts, priorSessionSkus);
        newSinceDate = priorSessionDate;
      // If we only have LOCAL/persistent priorSessionData, use that
      } else {
        const { sessionDate, sessionSkus } = priorSessionData || {};
        if (sessionDate && sessionSkus &&
            sessionDate !== currentSessionDate
        ) {
          newItemsByCategory = mapByCategory(allProducts, sessionSkus);
          newSinceDate = sessionDate;
          // Create NEW SESSION data only if:
          // 1) We don't have any saved
          // 2) We have newer prior-session data
          if (!sessionStorageDate || sessionDate !== priorSessionDate) {
            storeSessionValueStringified(
              currentSessionProductSkusKey,
              {
                sessionStorageDate: currentSessionDate,
                priorSessionDate: sessionDate,
                priorSessionSkus: sessionSkus,  // For same-day accumulation
              }
            )
          }
        }
      }

      devLog(`useNewlyAddedProducts: Categories wth new products: ${newItemsByCategory?.size || 0}, Current product count: ${allProducts.length}`);

      setNewlyAddedSinceDate(newSinceDate);
      setNewlyAddedByCategory(newItemsByCategory);

      // Here we're saving the persistent product sku data for a future session
      updateStoredSkuData(currentSessionDate, sessionProductSkus);
    }
  }, [allProducts, sessionProductSkus])

  return {
    newlyAddedSinceDate,
    newlyAddedByCategory,
  }
}

export default useNewlyAddedProducts;
