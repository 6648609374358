import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { aiGeneratedStrainInfoEndpoint } from '../../constants/api-endpoints';
import useFetch from '../util/useFetch';

const cleanStrainName = (strain) => {
  // Handle: strain (strain alternate)
  if (strain.indexOf('(') > -1) {
    return strain.substring(0, strain.indexOf('(') -1);
  } else {
    return strain;
  }
}

/**
 * Fetch and style the AI Strain Summary by SKU
 */
export const useAISummary = (dutchie_sku, dutchie_strain) => {

  const { user } = React.useContext(UserContext);

  const {
    data,
    ...rest
  } = useFetch(
    user,
    aiGeneratedStrainInfoEndpoint(dutchie_sku||'na'),
    true,         /* allowAnonymous */
    undefined,    /* urlSuffix */
    !dutchie_sku || !user /* paused */
  );

  const [strainDescription, setStrainDescription] = React.useState();
  const [includesStrainName, setIncludesStrainName] = React.useState();

  React.useEffect(() => {
    if (data?.strain_description) {

      const { strain_description } = data;

      const hybrid = 'hybrid';
      const strainRegex = new RegExp(`${cleanStrainName(dutchie_strain)}`, 'gi');
      const hybridRegex = new RegExp(`\\s${hybrid}\\s`, 'gi'); // Prevent splitting "indica-hybrid"
      const indicaRegex = new RegExp('indica-hybrid|indica-leaning|indica-dominant|indica', 'gi');
      const sativaRegex = new RegExp('sativa-hybrid|sativa-leaning|sativa-dominant|sativa', 'gi');
      setStrainDescription(
        strain_description
          .replace(strainRegex, '<b>$&</b>')
          .replace(indicaRegex, '<b class="typeI">$&</b>')
          .replace(sativaRegex, '<b class="typeS">$&</b>')
          .replace(hybridRegex, ' <b class="typeH">$&</b> ')  // Space needed!
      );
      setIncludesStrainName(/strainRegex/.test(strain_description));
    }
  }, [data, dutchie_strain]);

  return {
    strainDescription,
    includesStrainName,
    ...rest,
  };
}

export default useAISummary;
