import * as React from 'react';

import { getUrlHashedValue } from '../util/storage-utils';
import PropTypes from 'prop-types';

// Klaviyo abandon cart email click params
const CART_EMAIL_PARAM = 'cart_reminder';
// We may pass skus
const CART_SKU_PARAM = 'cart_skus';
// Klaviyo abandon cart email referral proxy domain for reporting
const CART_EMAIL_PROXY_DOMAIN = 'cartemail';

export const AttributionContext = React.createContext();

const AttributionProvider = ({children}) => {

  // Track source of shopping app traffic (especially for partner links)
  const [referralDomain, setReferralDomain] = React.useState();
  // Handle discount code in site link
  const [siteLinkDiscountCode, setSiteLinkDiscountCode] = React.useState();
  // Abandoned cart email-related
  const [isAbandonCartSession, setIsAbandonCartSession] = React.useState();
  const [abandonCartSkus, setAbandonCartSkus] = React.useState();

  // Listen for referrer message from host
  React.useEffect(() => {
    // Accept messages within iframe from marketing site (/framedLocationSearch)
    const trustedOrigins = ["https://zyprun.com"];

    const onMsg = (msg) => {
      if (!trustedOrigins.includes(msg.origin)) return;
      try {
        if (msg.data && !referralDomain) {
          const { referrer } = JSON.parse(msg.data) || {};
          if (referrer) {
            setReferralDomain(referrer);
            document.body.setAttribute('referrer', referrer);
          }
        }
      } catch(e) {}
    };
    if (window.top !== window.self) {
      window.addEventListener("message", onMsg);
    }
    return () => window.removeEventListener("message", onMsg);
  },[referralDomain]);

  // Check for url params/hash on app load for referrers
  React.useEffect(() => {
    let referrer;
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      if (params.has(CART_EMAIL_PARAM)) {
        referrer = CART_EMAIL_PROXY_DOMAIN;
      // Marketing emails: utm_source=Klaviyo
      } else if (params.has('utm_source')) {
        referrer = params.get('utm_source');
      }
    } else if (window.location.hash) {
      const encodedReferrer = getUrlHashedValue('ref');
      if (encodedReferrer) {
        referrer = window.atob(referrer);
      }
    }
    if (referrer) {
      setReferralDomain(referrer);
    } else if (document.referrer) {
      setReferralDomain(document.referrer);
    }
  }, []);

  // Handle discount code url
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('zrCode')) {
      setSiteLinkDiscountCode(urlParams.get('zrCode').trim());
    } else if (urlParams.has('zrcode')) {
      setSiteLinkDiscountCode(urlParams.get('zrcode').trim());
    } else if (urlParams.has('ZRCODE')) {
      setSiteLinkDiscountCode(urlParams.get('ZRCODE').trim());
    }
  }, []);

   // Handle abandon cart emails
   React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(CART_EMAIL_PARAM)) {
      setIsAbandonCartSession(true);
    }
    // Not currently used
    if (urlParams.has(CART_SKU_PARAM)) {
      setAbandonCartSkus(urlParams.get(CART_SKU_PARAM).split(','));
    }
  }, []);

  return  <AttributionContext.Provider value={{
            referralDomain,
            siteLinkDiscountCode,    // ?zrcode=FOO20
            setSiteLinkDiscountCode, // Used with Klaviyo subscribe
            isAbandonCartSession,    // ?cart_reminder=true
            abandonCartSkus,         // &cart_skus=ABC,DEF,GHI
          }}>
            {children}
          </AttributionContext.Provider>
}

AttributionProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default AttributionProvider;
